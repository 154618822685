<template>
  <b-card title="Accreditation">
    <b-tabs>
      <b-tab
        active
        @click="$router.push('/add-accreditation')"
      >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add Accreditation</span>
        </template>
        <AddAccreditation />
      </b-tab>
      <b-tab @click="$router.push('/all-accreditations')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>  Accreditation</span>
        </template>
        <router-view />
        <!-- <AllAccreditation></AllAccreditation> -->
      </b-tab>

    </b-tabs>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'
import AddAccreditation from './AddAccreditation.vue'

export default {
  components: {
    BCard,

    BTabs,
    BTab,

    AddAccreditation,
  },
  data() {
    return {

    }
  },
}
</script>
